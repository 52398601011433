import React from "react"

const PageSection = ({ post, notitle }) => (
  <div className="section">
    {!notitle ? (
      <h2>{post.frontmatter.title}</h2>
    ) : ("")}
    <div
      className="section-content"
      dangerouslySetInnerHTML={{ __html: post.html }}
    />
  </div>
)

export default PageSection
