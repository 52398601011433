import React from "react"

export default function Footer() {
  return(
    <div className="footer">
      <div>
        <p className="title">11th Illustration Research Symposium</p>
        <div className="links">
          <a href="https://www.instagram.com/illustration_research_methods/" target="_blank" rel="noreferrer">Instagram</a>, <a href="https://www.illustrationresearch.org/" target="_blank" rel="noreferrer">Research</a>
        </div>
        <br/>
        <p>Site by <a className="no-highlight" href="https://ollybromham.com" target="_blank" rel="noreferrer">Olly Bromham</a></p>
      </div>
      <img src="/ksa.jpg" alt="Kingston School of Art" />
    </div>
  )
}
