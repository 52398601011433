import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import Header from "../components/header";
import Footer from "../components/footer"
import PageSection from "../components/pageSection"

const ProgrammePage = ({
  data: {
    allMarkdownRemark: { edges },
  },
  location
}) => {
  const secret = "Conference2021";

  const Posts = edges
    .filter(edge => !!edge.node.frontmatter.title)
    .map(edge => <PageSection key={edge.node.id} post={edge.node} />)

  const [p, setClass] = useState();
  useEffect(() => {
    let param = new URLSearchParams(window.location.search).get('p');
    setClass(param);
    // Scramble links
    if (param !== secret) {
      let secrets = document.querySelectorAll('a.secret')
      for (let i = 0; i < secrets.length; i++) {
        secrets[i].href = "/";
      }
    }
  }, [])

  return (
    <div>
      <Header />
      <div className={`content programme ${p === secret ? "pass" : ""}`}>
        <h1>Programme</h1>
        <div className="inner">
          {Posts}
        </div>
        <Footer />
      </div>
    </div>
  )
}

export default ProgrammePage

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      filter: { fileAbsolutePath: {regex : "\//programme/"} },
      sort: { order: ASC, fields: [frontmatter___title] }
    ) {
      edges {
        node {
          id
          excerpt
          html
          frontmatter {
            title
          }
        }
      }
    }
  }
`
